import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 74,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Heebo, sans-serif",
    secondary: "Soe, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#00AE00",
      light: "#F8FDF7",
      dark: "#003400",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#D1D1D1",
      light: "#E9E9E9",
      dark: "#5F5F5F",
    },
    tertiary: {
      main: "#1C1C1C",
      light: "#F9F9F9",
      dark: "#E5F9F3",
    },
    text: {
      primary: "#003400",
      secondary: "#5F5F5F",
      content: "#000000",
      tertiary: "#15372C",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Heebo", "Soe", "Open Sans", "Arial", sans-serif`,
  },
})

export default theme
